/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  useTheme,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlashMessage from "../../components/FlashMessage";
import Footer1 from "../../components/Footer1/Footer";
import { LoginHeader } from "../../components/Header";
import Header from "../../components/Header/Header";
import { SearchContainer } from "../../components/HomeScreen";
import UserContext from "../../context/User";
import { useLocation } from "../../hooks";
import Analytics from "../../utils/analytics";
import useStyles from "./styles";
import * as Sentry from "@sentry/react";
import OrderEatRepeat from "../../assets/images/OrderEatRepeat.png";
import AppStore from "../../assets/images/AppStore.png";
import GooglePlay from "../../assets/images/GooglePlay.png";
import MerchantPartner from "../../assets/images/MerchantPartner.png";
import JoinRider from "../../assets/images/JoinRider.png";
import UserPlus from "../../assets/icons/UserPlus.svg";
import TickIcon from "../../assets/icons/Tick.svg";

function Home() {
  // const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const { error, loading } = useLocation();
  const [open, setOpen] = useState(!!error);
  const { isLoggedIn } = useContext(UserContext);
  let check = false;

  const handleButton = (path) => {
    navigate(path);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_HOME);
  }, []);
  useEffect(() => {
    if (check) {
      setOpen(!!error);
    } else {
      check = true;
    }
  }, [error]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleComingSoon = () => {
    setSnackbarMessage("Coming soon!");
    setSnackbarOpen(true);
  };

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Box className={classes.root}>
        <FlashMessage
          severity={loading ? "info" : "error"}
          alertMessage={error}
          open={open}
          handleClose={handleClose}
        />
        {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
        {/* serch container (1st) */}
        <Box>
          <Grid container item>
            <SearchContainer loading={loading} isHome={true} />
          </Grid>
        </Box>
        {/* app container (2nd) */}
        <Box className={classes.container} sx={{ pb: 8 }}>
          <Box
            sx={{
              background: "#E4D9FF",
              padding: 4,
            }}
            className={classes.leftContainer}
          >
            <Typography className={classes.heading}>
              Order. Eat. Repeat.
            </Typography>
            <Typography className={classes.subHeading}>
              All in one place using Borku.
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 300,
                color: "#211A1E",
                width: "50%",
                lineHeight: "15px",
                marginTop: 2,
              }}
            >
              Find All Your African Food & Groceries Here! Delivery to your
              doorstep anywhere in the UK
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 300,
                color: "#211A1E",
                lineHeight: "15px",
                marginTop: 2,
              }}
            >
              Download our App
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
              <Button onClick={handleComingSoon}>
                <img src={AppStore} alt="" style={{ height: 38, width: 118 }} />
              </Button>
              <Button onClick={handleComingSoon}>
                <img
                  src={GooglePlay}
                  alt=""
                  style={{ height: 38, widths: 118 }}
                />
              </Button>
            </Box>
          </Box>
          <img
            src={OrderEatRepeat}
            alt="fruits2"
            className={classes.containerImg}
          />
        </Box>
        <Box className={classes.container} sx={{ pb: 4 }}>
          <Box
            sx={{
              paddingLeft: 4,
              paddingRight: 4,
              marginBottom: 2,
            }}
            className={classes.leftContainer}
          >
            <Typography className={classes.heading}>
              Become a Merchant Partner
            </Typography>
            <Typography className={classes.subHeading}>
              Do you sell African food or{" "}
              <span style={{ color: theme.palette.primary.main }}>
                Groceries in the UK?
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 300,
                color: "#211A1E",
                width: "50%",
                lineHeight: "15px",
                marginTop: 2,
              }}
            >
              Why should you{" "}
              <span style={{ color: theme.palette.primary.main }}>
                partner with us?
              </span>
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 0.5, mt: 2 }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <img src={TickIcon} alt="Tick Icon" height="15" width="15" />
                <Typography
                  variant="caption"
                  sx={{ size: "11px", color: "#4A4A4A" }}
                >
                  Reach More Customers
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <img src={TickIcon} alt="Tickicon" height="15" width="15" />
                <Typography
                  variant="caption"
                  sx={{ size: "11px", color: "#4A4A4A" }}
                >
                  Earn More Money
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <img src={TickIcon} alt="Tick icon" height="15" width="15" />
                <Typography
                  variant="caption"
                  sx={{ size: "11px", color: "#4A4A4A" }}
                >
                  Grow Your Business
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: 10,
                fontWeight: 300,
                color: "#4A4A4A",
                lineHeight: "15px",
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              Let’s get you set up. It should only take a few minutes.
            </Typography>
            <Button
              className={classes.btn}
              endIcon={<img src={UserPlus} alt="" />}
              variant="hidden"
              disableElevation
              onClick={() => handleButton("/merchantRegistration")}
            >
              Sign Up
            </Button>
          </Box>
          <img
            src={MerchantPartner}
            alt="fruits2"
            className={classes.containerImg}
          />
        </Box>
        <Box className={classes.container}>
          <Box
            sx={{
              padding: 4,
            }}
            className={classes.leftContainer}
          >
            <Typography
              sx={{
                marginTop: 2,
                width: "50%",
              }}
              className={classes.heading}
            >
              Join us as a rider.
            </Typography>
            <Typography
              sx={{
                marginTop: 2,
                marginBottom: 2,
              }}
              className={classes.subHeading}
            >
              Ride with Borku and maximize your earning.
            </Typography>
            <Button
              className={classes.btn}
              endIcon={<img src={UserPlus} alt="" />}
              variant="hidden"
              disableElevation
              onClick={() => handleButton("/riderRegistration")}
            >
              Apply Now
            </Button>
          </Box>
          <img src={JoinRider} alt="" className={classes.containerImg} />
        </Box>
        {/* card container (3rd) */}

        {/* <Box className={classes.footerContainer}>
          <Box className={classes.footerWrapper}> */}
        {/* <Footer /> */}
        <Footer1 />
        {/* </Box>
        </Box> */}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        ContentProps={{
          sx: {
            backgroundColor: "#FFD8B8",
            color: "black",
          },
        }}
        message={snackbarMessage}
      />
    </Sentry.ErrorBoundary>
  );
}
export default Home;
